import React from 'react';
import '@lottiefiles/lottie-player';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Read Success</h1>
      <lottie-player
        class="lottie"
        autoplay
        src={process.env.PUBLIC_URL + '/tick.json'}>
      </lottie-player>
    </div>
  );
}

export default App;
